export enum AddonsEnum {
  WINTERSPORTS = 'WINTERSPORTS',
  CRUISE = 'CRUISE',
  VALUABLES = 'VALUABLES',
  CFAR = 'CFAR',
  ACTIONPACK = 'ACTIONPACK',
}

// empty value is required at the end of the array, because there should be CFAR, but currently it is not available
export const AddonsPossibleValues = [
  AddonsEnum.WINTERSPORTS,
  AddonsEnum.CRUISE,
  AddonsEnum.VALUABLES,
  AddonsEnum.CFAR,
  AddonsEnum.ACTIONPACK,
];

export const OptionsPossibleValues = ['0', '150', '250', '500'];

export const CancellationLimitPossibleValues = [
  '1000',
  '2000',
  '5000',
  '10000',
  '15000',
  '20000',
  '25000',
  '50000',
  'Unlimited',
];
