export const ProxyDateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
export const ApiDateFormat = 'yyyy-MM-dd';
export const ApiOutputDateFormat = 'YYYY-MM-DD';
export const UrlDateFormat = 'ddd, DD MMM YYYY HH:mm:ss [GMT]';

export const PRODUCT_ROWS_SHOWN = 5;
export const LINK_EXPIRATION_DAYS = 21;
export const REFRESH_INTERVAL = 3000;
export const AMT_MIN_TRIP_DURATION = 365;
export const LEAVE_TOUCH_DELAY = 20000;
export const LEAVE_DELAY = 500;
export const PROPOSAL_DOC_TO_SHOW = ['PDS', 'PW'];
export const MAX_INPUT_LENGTH = 99;
export const MAX_DESTINATIONS = 33;
export const CANCELLATION_LIMIT_UNLIMITED_VALUE = 1000000;
export const FLEXIBLE_CANCELLATION_LIMIT = 10000;

export const PanelRightVisibleForComponent = [
  'QuotationInformationForm',
  'QuotationProposals',
  'QuotationRecapDeclarations',
  'QuotationConfirmation',
];
