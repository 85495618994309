import { useCallback, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import {
  BookingConfig,
  BookingConfigEnum,
} from '../BookingWidget/bookingSteps.interface';
import useApi from '../../shared/services/api.service';
import { useSessionContext } from '../../context/sessionContext/sessionContext';
import { AdditionalDataProposal } from './components/quotationProposal.interface';

interface FetchAddonsConfigResult {
  addonsConfig: AdditionalDataProposal | null;
  loading: boolean;
  error: string | null;
  fetchAddonsConfig: (catalogVersion: string) => Promise<void>;
}

const useFetchAddonsConfig = (
  bookingConfigData: BookingConfig,
  showNotification: any,
): FetchAddonsConfigResult => {
  const [addonsConfig, setAddonsConfig] =
    useState<AdditionalDataProposal | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);

  // Memoize API URL and request headers
  const { apiCM360Url, catalog, requestHeaders } = useMemo(() => {
    if (
      !bookingConfigData ||
      !bookingConfigData[BookingConfigEnum.DataCenter]
    ) {
      return { apiCM360Url: '', catalog: '', requestHeaders: {} };
    }

    const cm360Endpoint: string =
      bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Endpoint;

    const { psClient } = bookingConfigData[BookingConfigEnum.Channel] || {};
    const catalogInternal = bookingConfigData.channel?.catalogGroup || '';

    const headers = {
      headers: {
        'Client-Id': psClient || '',
      },
    };

    return {
      apiCM360Url: cm360Endpoint,
      catalog: catalogInternal,
      requestHeaders: headers,
    };
  }, [bookingConfigData]);

  const fetchAddonsConfig = useCallback(
    async (catalogVersion: string): Promise<void> => {
      if (!catalogVersion) {
        setError('Missing catalogVersion parameter');
        return;
      }

      if (!apiCM360Url || !catalog) {
        setError('Missing API configuration');
        return;
      }

      setLoading(true);
      try {
        const url = `${apiCM360Url}/booking/addons-config?catalog=${catalog}&version=${catalogVersion}`;
        const response: AxiosResponse<AdditionalDataProposal> = await API.get(
          encodeURI(url),
          requestHeaders,
        );

        if (response.status !== 200) {
          showNotification('fetchingAddonsConfig', 'error', false);
          setError('fetchingAddonsConfig');
          throw new Error(
            `Error fetching addons config: ${response.statusText}`,
          );
        }

        const fetchedData = response.data;
        setAddonsConfig(fetchedData);
      } catch (errorResponse) {
        showNotification('fetchingAddonsConfig', 'error', false);
        setError('fetchingAddonsConfig');
      } finally {
        setLoading(false);
      }
    },
    [API, apiCM360Url, catalog, requestHeaders, showNotification],
  );

  return { addonsConfig, fetchAddonsConfig, loading, error };
};

export default useFetchAddonsConfig;
