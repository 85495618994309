import {
  BookingContextState,
  BookingStepsContextState,
} from './bookingContext.types';
import {
  BookingStepValueEnum,
  BookingSubStepOptionEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { isAgent } from '../../shared/helpers/auth.helper';
import getUrlSearchParam from '../../shared/helpers/urlSearchParams.helper';
import {
  BookingDataEnum,
  BookingDataResponse,
} from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';

const getDefaultCurrentStep = (): string => {
  const urlParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  const token: string | null = getUrlSearchParam('token', urlParams);
  if (token) {
    localStorage.setItem('token', token);
  } else {
    localStorage.removeItem('token');
  }
  return BookingStepValueEnum.QuotationForm;
};

export const contextDefaultValues: BookingContextState = {
  bookingSteps: {
    [BookingStepValueEnum.CurrentStep]: getDefaultCurrentStep(),
    [BookingStepValueEnum.Partner]: '',
    [BookingStepValueEnum.AggregatorId]: '',
    [BookingStepValueEnum.AffiliateId]: '',
    [BookingStepValueEnum.QuotationPreliminaryDeclarations]: null!,
    [BookingStepValueEnum.QuotationForm]: {
      [BookingSubStepValueEnum.TripDetails]: {
        [BookingSubStepOptionEnum.Value]: '',
        [BookingSubStepOptionEnum.Label]: '',
      },
      [BookingSubStepValueEnum.MaxTripDuration]: {
        [BookingSubStepOptionEnum.Value]: '',
        [BookingSubStepOptionEnum.Label]: '',
      },
      [BookingSubStepValueEnum.GeographicalZone]: [],
      [BookingSubStepValueEnum.DepartureDate]: '',
      [BookingSubStepValueEnum.ReturnDate]: '',
      [BookingSubStepValueEnum.TravellersAge]: [{ age: '' }],
      [BookingSubStepValueEnum.PromoCode]: '',
      [BookingSubStepValueEnum.TripCost]: undefined,
    },
    [BookingStepValueEnum.QuotationProposals]: {
      [BookingSubStepValueEnum.TotalPrice]: 0,
      [BookingSubStepValueEnum.Currency]: 'AUD',
      [BookingSubStepValueEnum.Proposal]: null!,
      [BookingSubStepValueEnum.Tables]: '',
      [BookingSubStepValueEnum.Addons]: {},
      [BookingSubStepValueEnum.Excess]: '',
      [BookingSubStepValueEnum.CancellationLimit]: '',
      [BookingSubStepValueEnum.MaxTripDuration]: '',
      [BookingSubStepValueEnum.ProductType]: '',
    },
    [BookingStepValueEnum.QuotationInformation]: {
      [BookingSubStepValueEnum.InformationPersonal]: null!,
      [BookingSubStepValueEnum.InformationMedical]: null!,
      [BookingSubStepValueEnum.InformationTravellers]: null!,
      [BookingSubStepValueEnum.MedicalScreening]: [],
    },
    [BookingStepValueEnum.QuotationRecapDeclaration]: null!,
    [BookingStepValueEnum.QuotationConfirmation]: null!,
    [BookingStepValueEnum.ExtraFields]: {
      [BookingDataEnum.ExternalBookingId]: '',
      [BookingDataEnum.ExternalBacklink]: '',
      [BookingDataEnum.InitialQuoteId]: '',
    },
  },
  pendingGTMTransaction: null,
  update: (): void => undefined,
  updateDefault: (): void => undefined,
  loadQuote: (): void => undefined,
  loadBooking: (): void => undefined,
  bookingReferenceId: null,
  setBookingReferenceId: (): void => undefined,
  setCurrentStep: (): void => undefined,
  saveQuoteAndBookingDataAction: (): Promise<BookingDataResponse> =>
    new Promise(null!),
  shallowUpdate: (
    callback: (data: BookingStepsContextState) => BookingStepsContextState,
  ) => undefined,
  setPendingGTMTransaction: (): void => undefined,
};

export default contextDefaultValues;
