enum InsuranceEnum {
  BookingDate = 'bookingDate',
  StartDate = 'startDate',
  EndDate = 'endDate',
  PNR = 'pnr',
  InsuranceData = 'insuranceData',
}

enum InsuranceDataEnum {
  DestinationCountry = 'destinationCountry',
  State = 'state',
  Brand = 'brand',
  SalesChannel = 'salesChannel',
  ShopId = 'shopId',
  AgentId = 'agentId',
  Duration = 'duration',
  InsuranceAddons = 'insuranceAddons',
  Excess = 'excess',
  CancellationLimit = 'cancellationLimit',
  PartnerCode = 'partnerCode',
  MaxTripDuration = 'maxTripDuration',
  AgentSales = 'agentSales',
  CreditAmount = 'creditAmount',
  AmountToInsure = 'amountToInsure',
  DiscretionaryDiscountAmount = 'dd_amount',
  DiscretionaryDiscountPercentage = 'dd_rate',
  DiscretionaryDiscountReason = 'dd_reason',
  BookingReferenceId = 'pnr', // this value is 'bookingReferenceId' on our FE side, 'externalId' on our BE side, and 'pnr' on P&S.
  LeadTime = 'leadTime',
}

interface InsuranceData {
  [InsuranceDataEnum.DestinationCountry]: string;
  [InsuranceDataEnum.State]: string;
  [InsuranceDataEnum.Brand]: string;
  [InsuranceDataEnum.SalesChannel]?: string;
  [InsuranceDataEnum.ShopId]?: string;
  [InsuranceDataEnum.AgentId]?: string;
  [InsuranceDataEnum.Duration]?: number;
  [InsuranceDataEnum.InsuranceAddons]: string;
  [InsuranceDataEnum.Excess]?: string;
  [InsuranceDataEnum.CancellationLimit]?: string;
  [InsuranceDataEnum.PartnerCode]: string;
  [InsuranceDataEnum.MaxTripDuration]?: string;
  [InsuranceDataEnum.AgentSales]?: string;
  [InsuranceDataEnum.CreditAmount]?: string;
  [InsuranceDataEnum.AmountToInsure]?: number;
  [InsuranceDataEnum.LeadTime]?: string;
}

interface InsurancePayload {
  [InsuranceEnum.BookingDate]?: string;
  [InsuranceEnum.StartDate]: string;
  [InsuranceEnum.EndDate]: string;
  [InsuranceEnum.InsuranceData]: InsuranceData;
  [InsuranceDataEnum.BookingReferenceId]?: string;
}

export { InsurancePayload, InsuranceEnum, InsuranceData, InsuranceDataEnum };
