import { useCallback, useContext, useState } from 'react';
import { AxiosResponse } from 'axios';
import {
  BookingConfig,
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../BookingWidget/bookingSteps.interface';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';
import { getPayloadForQuotationProposal } from './components/quotationProposal.helper';
import useApi from '../../shared/services/api.service';
import { BookingQuoteContext } from '../../context/quoteContext/quoteContext';
import { BookingDataResponse } from '../BookingWidget/BookingManagment/bookingManagment.interface';
import { BookingDataContext } from '../../context/bookingDataContext/bookingDataContext';
import { CacheDataContext } from '../../context/cacheContext/cacheContext';
import { BookingContext } from '../../context/bookingContext/bookingContext';
import { useSessionContext } from '../../context/sessionContext/sessionContext';
import { BookingConfigContext } from '../../context/bookingConfigContext/bookingConfigContext';

interface FetchResult {
  products: Product[] | null;
  loading: boolean;
  error: string | null;
  fetchData: () => Promise<void>;
}

export interface BookingWithProducts extends BookingDataResponse {
  products: Product[];
}

export interface PsProposalsResponse {
  requestId: string;
  version: string;
  errors: boolean;
  proposals: Proposal[];
}

export interface Proposal {
  error: boolean;
  products: Product[];
  totalPrice: Price;
}

export enum ProductEnum {
  Code = 'code',
  BeneficiaryIndex = 'beneficiaryIndex',
  Description = 'description',
  Selected = 'selected',
  Mandatory = 'mandatory',
  Price = 'price',
  Covers = 'covers',
  AdditionalValues = 'additionalValues',
  Label = 'label',
  ProductDocuments = 'productDocuments',
}

export interface Product {
  [ProductEnum.Code]: string;
  [ProductEnum.BeneficiaryIndex]: number;
  [ProductEnum.Description]: string;
  [ProductEnum.Selected]: boolean;
  [ProductEnum.Mandatory]: boolean;
  [ProductEnum.Price]: Price;
  [ProductEnum.Covers]: ProductCovers[];
  [ProductEnum.AdditionalValues]: { [key: string]: string };
  [ProductEnum.Label]: {
    title: string;
    content: string;
  };
  [ProductEnum.ProductDocuments]: ProductDocument[];
}

export enum ProductDocumentEnum {
  Key = 'key',
  Label = 'label',
  Link = 'link',
}

export interface ProductDocument {
  [ProductDocumentEnum.Key]: string;
  [ProductDocumentEnum.Label]: string;
  [ProductDocumentEnum.Link]: string;
}

export interface ProductCovers {
  code: string;
  productCode?: string;
  description: string;
  limits: string;
  label: Label;
}

export interface Label {
  title: string;
  content: string;
}

export interface Price {
  currency: string;
  tax: number;
  grossPremium: number;
  netPrice: number;
  commission: number;
  promotion: boolean;
  grossPremiumBeforePromotion: number;
  markUp: number;
}

const useFetchProposals = (
  bookingSteps: BookingStepsContextState,
  bookingConfigData: BookingConfig,
  showNotification: any,
): FetchResult => {
  const [products, setProducts] = useState<Product[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { update, setBookingReferenceId, bookingReferenceId } =
    useContext(BookingContext);
  const { bookingQuoteResponse } = useContext(BookingQuoteContext);
  const { setCacheValue } = useContext(CacheDataContext);
  const { bookingDataResponse, updateBookingData } =
    useContext(BookingDataContext);
  const { loadAddonsConfig } = useContext(BookingConfigContext);
  const { decodedSessionToken } = useSessionContext();
  const apiCM360Url: string =
    bookingConfigData[BookingConfigEnum.DataCenter].cm360Endpoint;
  const payload: string = getPayloadForQuotationProposal(
    bookingSteps,
    bookingConfigData,
    bookingQuoteResponse,
    bookingDataResponse,
    true,
    decodedSessionToken,
    bookingReferenceId ?? bookingDataResponse?.bookingData.externalId,
  );
  const { psClient } = bookingConfigData[BookingConfigEnum.Channel];
  const requestHeaders = {
    headers: {
      'Client-Id': psClient,
    },
  };
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);

  const createBookingAndFetchProducts = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const url = `${apiCM360Url}/booking/from-proposal-request?catalog=${bookingConfigData.channel.catalogGroup}`;
      const response: AxiosResponse<BookingWithProducts> = await API.post(
        encodeURI(url),
        payload,
        requestHeaders,
      );

      if (response.status !== 201) {
        showNotification('fetchingData', 'error', false);
        setError('fetchingData');
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      if (response.headers['x-amzn-remapped-authorization']) {
        localStorage.setItem(
          'token',
          response.headers['x-amzn-remapped-authorization'].replace(
            'Bearer ',
            '',
          ),
        );
      }

      const fetchedData = response.data;
      updateBookingData(fetchedData);
      setProducts(fetchedData.products);

      if (fetchedData.bookingData?.catalogVersion) {
        await loadAddonsConfig(fetchedData.bookingData.catalogVersion);
      }
    } catch (errorResponse) {
      showNotification('fetchingData', 'error', false);
    } finally {
      setLoading(false);
    }
  }, [
    API,
    apiCM360Url,
    bookingConfigData,
    payload,
    showNotification,
    updateBookingData,
    loadAddonsConfig,
  ]);

  const reinitializeBookingAndGetProducts =
    useCallback(async (): Promise<void> => {
      setLoading(true);
      setCacheValue('isSaveQuoteAndBookingDataLoading', true);
      try {
        const url = `${apiCM360Url}/booking/${bookingDataResponse?.bookingData?.id}/re-init?catalog=${bookingConfigData.channel.catalogGroup}`;
        const response: AxiosResponse<BookingWithProducts> = await API.put(
          url,
          payload,
          requestHeaders,
        );

        if (response.status !== 201) {
          showNotification('fetchingData', 'error', false);
          setError('fetchingData');
          throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const fetchedData: BookingWithProducts = response.data;
        const { code } =
          bookingSteps[BookingStepValueEnum.QuotationProposals]?.[
            BookingSubStepValueEnum.Proposal
          ] || {};
        if (code) {
          const selectedProduct = fetchedData.products.find(
            (product: Product) => product.code === code,
          );
          if (selectedProduct) {
            update(
              selectedProduct,
              BookingStepValueEnum.QuotationProposals,
              BookingSubStepValueEnum.Proposal,
            );
          }
        }
        if (fetchedData?.bookingData?.externalId) {
          setBookingReferenceId(fetchedData?.bookingData?.externalId);
        }
        updateBookingData(fetchedData);
        setProducts(fetchedData.products);

        if (fetchedData.bookingData?.catalogVersion) {
          await loadAddonsConfig(fetchedData.bookingData.catalogVersion);
        }
      } catch (errorResponse) {
        showNotification('fetchingData', 'error', false);
      } finally {
        setCacheValue('isSaveQuoteAndBookingDataLoading', false);
        setLoading(false);
      }
    }, [
      API,
      apiCM360Url,
      bookingConfigData,
      payload,
      showNotification,
      updateBookingData,
      loadAddonsConfig,
    ]);

  const fetchData = useCallback(async (): Promise<void> => {
    if (bookingDataResponse) {
      await reinitializeBookingAndGetProducts();
    } else {
      await createBookingAndFetchProducts();
    }
  }, [
    bookingDataResponse,
    createBookingAndFetchProducts,
    reinitializeBookingAndGetProducts,
  ]);

  return { products, fetchData, loading, error };
};

export default useFetchProposals;
