import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  BookingConfig,
  BookingConfigEnum,
  Country,
} from '../../components/BookingWidget/bookingSteps.interface';
import { BookingConfigContextState } from './bookingConfigContext.types';
import { bookingConfigInit } from '../../components/BookingWidget/BookingConfig/bookingConfig';
import useFetchAddonsConfig from '../../components/QuotationProposals/useFetchAddonsConfig';
import { NotificationContext } from '../../shared/components/Notification/NotificationContext';

const contextDefaultValues: BookingConfigContextState = {
  bookingConfigData: bookingConfigInit,
  updateConfig: (bookingConfig: BookingConfig): void => undefined,
  getCountry: (): Country | null => null,
  addonsConfig: null,
  loadAddonsConfig: async (catalogVersion: string) => undefined,
};

export const BookingConfigContext =
  createContext<BookingConfigContextState>(contextDefaultValues);

export const useBookingConfigContext = (): BookingConfigContextState =>
  useContext(BookingConfigContext);

export const BookingConfigContextProvider: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => {
  const [bookingConfigData, setBookingConfig] = useState<BookingConfig>(
    contextDefaultValues.bookingConfigData,
  );
  const { showNotification } = useContext(NotificationContext);

  const handleUpdate = (bookingConfig: BookingConfig): void =>
    setBookingConfig(bookingConfig);

  const getCountry = (): Country | null =>
    bookingConfigData[BookingConfigEnum.Country] || null;

  // Use the useFetchAddonsConfig hook
  const { addonsConfig, fetchAddonsConfig } = useFetchAddonsConfig(
    bookingConfigData,
    showNotification,
  );

  const loadAddonsConfig = useCallback(
    async (catalogVersion: string): Promise<void> => {
      await fetchAddonsConfig(catalogVersion);
    },
    [fetchAddonsConfig],
  );

  const bookingConfig: BookingConfigContextState = useMemo(
    () => ({
      bookingConfigData,
      updateConfig: handleUpdate,
      getCountry,
      addonsConfig,
      loadAddonsConfig,
    }),
    [bookingConfigData, getCountry, addonsConfig, loadAddonsConfig],
  );

  return (
    <BookingConfigContext.Provider value={bookingConfig}>
      {children}
    </BookingConfigContext.Provider>
  );
};
