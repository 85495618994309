import React, { useContext, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuotationElementBlockPrice from '../../QuotationsBlockShare/QuotationPriceElement/QuotationElementBlockPrice';
import { usePriceSummaryLoading } from '../../../context/priceSummaryContext/priceSummaryContext';
import { AgentConfigContext } from '../../../context/agentContext/agentConfigContext';

const PriceSummaryRetailNet: React.FC = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { additionalPrices } = usePriceSummaryLoading();
  const { isAgentView } = useContext(AgentConfigContext);

  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };

  if (
    !isAgentView ||
    !additionalPrices?.retailNet ||
    Number.isNaN(parseFloat(additionalPrices.retailNet))
  )
    return null;

  return (
    <Box sx={{ marginTop: '0.5rem' }}>
      <Box
        onClick={handleExpandClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          color: 'var(--dark-secondary)',
          opacity: 0.7,
          '&:hover': {
            opacity: 1,
          },
          width: 'fit-content',
        }}
        data-cy="bookingW_Quote_moreInfo"
      >
        <span className="label summary" style={{ fontSize: '0.875rem' }}>
          {t('BOOKING.QUOTATION_SUMMARY_BLOCK.moreInformation') ||
            'More information'}
        </span>
        <ExpandMoreIcon
          sx={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s',
            marginLeft: '0.25rem',
            fontSize: '1.25rem',
          }}
        />
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{ marginTop: '0.5rem' }}>
          <QuotationElementBlockPrice
            fontWeightLabel="bold"
            product={[
              {
                productName:
                  t('BOOKING.QUOTATION_SUMMARY_BLOCK.retailNet') ||
                  'Retail net',
                amount: parseFloat(additionalPrices.retailNet).toFixed(2),
              },
            ]}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default PriceSummaryRetailNet;
